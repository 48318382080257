import Cookies from 'js-cookie'

/**
 * Get location
 * 
 * Steps:
 * 1. using coordinates (if allowed)
 * 2. using IP (if first step false)
 */


export default class Location {
  constructor() {
    this.position = null
    this.success = false
    this.userLocation = "your location"

    this.init()
  }

  async init() {
    if (!Cookies.get(themeVars.cookieName)) {
      await this.getLocation()
    }
  }

  async getLocation() {
    try {
      await this.getCoordinates();
      await this.getGoogleLocation();
    } catch (error) {
      console.error("Google Location failed:", error);
    } finally {
      if (!this.success) {
        await this.getIpGeoLocation();
      }
    }
  }

  getCoordinates() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.position = {
              lat: parseFloat(position.coords.latitude),
              lng: parseFloat(position.coords.longitude),
            };
            resolve(this.position);
          },
          (error) => {
            console.error("Error: ", error.message || error);
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  }

  async getGoogleLocation() {
    if (typeof google !== 'undefined' && typeof google.maps !== 'undefined') {
      const geocoder = new google.maps.Geocoder();

      try {
        const { results } = await geocoder.geocode({ location: this.position });
        console.log('getGoogleLocation :>> ', results);

        if (results && results.length > 0) {
          const country = results[0].address_components.find(component =>
            component.types.includes("country")
          );

          const stateComponent = results[0].address_components.find(component =>
            component.types.includes("administrative_area_level_1")
          );

          await this.saveLocation(country.short_name, stateComponent.long_name);
          this.success = true;
        }
      } catch (e) {
        console.error("Geocoder failed due to: ", e);
      }
    }
  }

  async getIpGeoLocation() {
    const formData = new FormData();
    formData.append("action", 'get_user_location');

    const response = await fetch(themeVars.ajaxurl, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
    });

    const data = await response.json();
    
    if (response.ok && data.success) {
      const apiResponse = data.data;

      console.log('getIpGeoLocation :>> ', apiResponse);

      await this.saveLocation(apiResponse.country_code2, apiResponse.state_prov)
    }
   
  }

  async saveLocation(country, state) {
    if (["CA", "US"].includes(country)) {
      this.userLocation = state;
    }

    Cookies.set(themeVars.cookieName, this.userLocation, { expires: 7, path: '/' })
    this.updateShortcodes();
  }

   async updateShortcodes() {
    const stateShortcodes = document.querySelectorAll('.shortcode-state');

    stateShortcodes.forEach(shortcode => {
      const stateCookie = Cookies.get(themeVars.cookieName);
      shortcode.innerHTML = shortcode.innerHTML.replace(/\[state\]/g, stateCookie);
    });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  new Location()
});
