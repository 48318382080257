function mobileMenu() {
  const menuItems = document.querySelectorAll('button.menu-item-has-children');
  const backBtns = document.querySelectorAll('.btn-back-level');

  menuItems.forEach(function (link) {
    link.addEventListener('click', function(e) {
      e.preventDefault();

      const second = e.target.getAttribute('second')
      const firstLevel = document.querySelector('.first-level');

      const nextLevel = document.getElementById('fl-' + second);
      nextLevel?.classList.add('show')
      firstLevel?.classList.remove('show')
    });
  });

  backBtns.forEach(function (btn) {
    btn?.addEventListener('click', function() {
      const flMenu = document.querySelector('.fl-menu.show');
      const firstLevel = document.querySelector('.first-level');
      const secondLevel = document.querySelector('.second-level.show');

      flMenu?.classList.remove('show')
      firstLevel?.classList.add('show')
      secondLevel?.classList.remove('show')
    });
  });
}
mobileMenu()

function dektopMenu() {
  const menuItems = document.querySelectorAll('a.menu-item-has-children');

  menuItems.forEach(function (link) {
    link.addEventListener('click', function(e) {
      e.preventDefault();



      let parentItem = this.closest('.main-menu__item');
      let subMenu = parentItem.querySelector('.sub-menu');

      if (subMenu.classList.contains('show')) {
        subMenu?.classList.remove('show');
      } else {

        menuItems.forEach(function(item) {
          item?.closest('.main-menu__item')?.querySelector('.sub-menu')?.classList.remove('show');
        });
        
        subMenu?.classList.add('show');
      }
    });
  });

  document.addEventListener('click', function (e) {
    if (!e.target.closest('.menu-item-has-children')) {
      menuItems.forEach(function(item) {
       item?.closest('.main-menu__item')?.querySelector('.sub-menu')?.classList.remove('show');
      });
    }
  });
}
dektopMenu()

// Hamburger
const hamburgerMenu = document.getElementById('hamburger-menu');
const headerNav = document.getElementById('header-nav');
const searchWrap = document.querySelector('.search-wrapper');
const body = document.querySelector('body');
let lastScrollTop = 0;

hamburgerMenu?.addEventListener('click', () => {
  if (headerNav.classList.contains('active')) {
    // close
    headerNav.classList.remove('active', 'show');
    hamburgerMenu.classList.remove('active');

    body.classList.remove('fixed');
    window.scrollTo(0, lastScrollTop); 
    body.style.removeProperty('margin-left');
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');
  } else {
    // open
    headerNav.classList.add('active', 'show');
    hamburgerMenu.classList.add('active');
    searchWrap.classList.remove('show');
    
    lastScrollTop = window.scrollY; 
    body.classList.add('fixed');
    body.style.top = `-${lastScrollTop}px`;
  }
});
