document.addEventListener("DOMContentLoaded", function () {
  setTimeout(() => {
    const testimonialBlocks = document.querySelectorAll('.testimonials-block');

    testimonialBlocks.forEach((block) => {
      const reviewItems = block.querySelectorAll('.ti-review-item');
      const dotWrap = block.querySelector('.testimonials-block__pagination');

      if (dotWrap) {
        dotWrap.innerHTML = '';
      }

      reviewItems.forEach((e, i) => {
        const id = e.getAttribute('data-id');
        const bullet = document.createElement('span');
        bullet.className = 'ti-pagination-bullet';
        bullet.dataset.index = id;
        bullet.dataset.i = i;

        bullet.addEventListener('click', () => {
          reviewItems.forEach(item => item.classList.remove('active'));
          reviewItems[i].classList.add('active');
        });

        dotWrap?.appendChild(bullet);
      });

      // Observer for the first review item
      const firstReviewItem = block.querySelector('.ti-review-item');
      if (firstReviewItem) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
              reviewItems.forEach((e) => {
                const id = e.getAttribute('data-id');
                const isHidden = e.classList.contains('ti-hidden-review');
                const bullet = block.querySelector(`[data-index="${id}"]`);
                isHidden ? bullet?.classList.remove('active') : bullet?.classList.add('active');
              });
            }
          });
        });

        observer.observe(firstReviewItem, { attributes: true });
      }

      // Custom pagination click within the current block
      const paginationItems = block.querySelectorAll('.ti-pagination-bullet');
      paginationItems.forEach((e) => {
        e.addEventListener('click', () => {
          const currentId = parseInt(e.getAttribute('data-i'), 10);
          const tiNext = block.querySelector(`.ti-next`);
          const tiPrev = block.querySelector(`.ti-prev`);

          const activePaginations = block.querySelectorAll('.ti-pagination-bullet.active');
          const activePaginationArray = Array.from(activePaginations);
          const firstActivePagination = activePaginationArray[0];
          const lastActivePagination = activePaginationArray[activePaginationArray.length - 1];
          const firstActive = parseInt(firstActivePagination.getAttribute('data-i'), 10);
          const lastActive = parseInt(lastActivePagination.getAttribute('data-i'), 10);

          let countClick;
          let direction;

          if (currentId > firstActive) {
            countClick = currentId - lastActive;
            direction = tiNext;
          } else if (currentId < firstActive) {
            countClick = firstActive - currentId;
            direction = tiPrev;
          }

          if (countClick && direction) {
            for (let i = 0; i < countClick; i++) {
              setTimeout(() => {
                direction.click();
              }, i * 600);
            }
          }
        });
      });
    });
  }, 100);

  // Fix for https://www.notion.so/buzzword/Display-issue-Left-column-overlaps-with-reviews-1fbb7645d0db4fd09572da4954a83cf8?pvs=4
  // Finds the height of the interior rail and interior content and adds a class to the testimonial wrapper if the rail is taller than the content
  // This class will add padding to the testimonial wrapper to prevent the left column from overlapping with the reviews

  // Get elements with corresponding classes
  var interiorRailWrapper = document.querySelector('.interior-rail-wrapper');
  var interiorContentWrapper = document.querySelector('.interior-content-wrapper');
  var testimonialWrapper = document.querySelector('.section-home-widget .testimonial-wrapper');

  if (interiorRailWrapper && interiorContentWrapper && testimonialWrapper) {
    var railHeight = interiorRailWrapper.clientHeight;
    var contentHeight = interiorContentWrapper.clientHeight;

    // Compare heights and add class if rail is taller than content
    if (railHeight > contentHeight) {
      testimonialWrapper.classList.add('small-wrapper');
    }
  }
});
