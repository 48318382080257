document.addEventListener('DOMContentLoaded', function () {
  const handleFormSubmit = (form, action, onSuccess) => {
    form.addEventListener('submit', function (event) {
      event.preventDefault();
      
      const zipCode = form.querySelector('.input-zipcode')?.value;
      if (!zipCode) return;
      
      const formData = new FormData();
      formData.append("action", action);
      formData.append("zipCode", zipCode);
      
      fetch(themeVars.ajaxurl, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => response.json().then(data => response.ok ? data : Promise.reject(data)))
      .then(onSuccess)
      .catch((error) => {
        console.error("Error: ", error.message || error);
        alert(error.message || "An error occurred");
      });
    });
  };

  // Handler for zip forms
  const zipForms = document.querySelectorAll('.js-type-code');
  zipForms.forEach(zipForm => {
    handleFormSubmit(zipForm, "check_zip_code", (data) => {
      if (data.success) {
        window.location.href = data.data.redirect;
      } else {
        alert(data.message);
      }
    });
  });

  // Handler for state forms
  const stateForms = document.querySelectorAll('.js-type-state');
  stateForms.forEach(stateForm => {
    handleFormSubmit(stateForm, "save_user_state", () => {
      window.location.reload();
    });
  });
});
